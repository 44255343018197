import React, { useState, useEffect } from 'react'
import axios from 'axios'

const EmailChange = () => {
    const [feedback, setFeedback] = useState()

    useEffect(() => {
        let params = new URL(document.location).searchParams
        const code = params.get('code')
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_URL}users-permissions/users/confirm-change-email`,
                {
                    code,
                }
            )

            .then(response => {
                setFeedback(
                    `Du hast deine E-Mail Adresse erfolgreich geändert!`
                )
            })
            .catch(error => {
                setFeedback(`Fehler beim Zurücksetzen`)
                console.log(error)
            })
    }, [])
    return (
        <main className="max-w-sm mx-auto">
            <div className="text-center">
                <h2 className="text-2xl">E-Mail Änderung bestätigen</h2>

                <p className="my-8">{feedback}</p>
                <a
                    href={`${process.env.REACT_APP_MAGIC_LINK}`}
                    className="w-full px-4 py-2 my-2 text-center text-white border rounded-lg border-primary bg-gradient-to-r from-primary to-primary-dark">
                    Zurück zur TauchApp
                </a>
            </div>
        </main>
    )
}

export default EmailChange
