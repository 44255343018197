import React, { useState, useEffect } from 'react'
const EmailConfirmation = () => {
    const [success, setSuccess] = useState('false')
    useEffect(() => {
        let params = new URL(document.location).searchParams
        setSuccess(params.get('success'))
    }, [])

    return (
        <main className="max-w-sm mx-auto">
            <div className="text-center">
                {success === 'true' && (
                    <div>
                        <h2 className="mb-4 text-xl text-green-700">
                            E-Mail-Adresse bestätigt.
                        </h2>
                        <h3 className="mb-6 text-lg">
                            Du kannst die TauchApp jetzt uneingeschränkt nutzen,
                            viel Spaß!
                        </h3>
                    </div>
                )}
                {success === 'false' && (
                    <div>
                        <h3 className="mb-6 text-lg">
                            Ups, da ist etwas schief gelaufen.
                        </h3>
                        <h3 className="mb-6 text-lg">
                            Dein Token ist entweder abgelaufen oder wurde schon
                            bestätigt.
                        </h3>
                    </div>
                )}
                <a
                    href={`${process.env.REACT_APP_MAGIC_LINK}`}
                    className="w-full px-4 py-2 my-2 text-center text-white border rounded-lg border-primary bg-gradient-to-r from-primary to-primary-dark">
                    Zurück zur TauchApp
                </a>
            </div>
        </main>
    )
}

export default EmailConfirmation
