import React from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import Logo from './Logo'

import PasswordReset from './PasswordReset'
import EmailChange from './EmailChange'
import EmailConfirmation from './EmailConfirmation'

function App() {
    return (
        <Router>
            <div className="container flex flex-col justify-between h-screen mx-auto">
                <div>
                    <Logo className="mx-auto my-12" />
                    <Switch>
                        <Route
                            path="/email-confirmation"
                            component={EmailConfirmation}></Route>
                        <Route
                            path="/change-email"
                            component={EmailChange}></Route>
                        <Route
                            path="/reset-password"
                            component={PasswordReset}></Route>
                        <Route path="/">
                            <main className="grid max-w-sm grid-cols-2 gap-4 mx-auto text-center">
                                <a
                                    href="https://apps.apple.com/de/app/page/id1571124791"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="w-full px-4 py-2 my-2 text-center text-white border rounded-lg border-primary bg-gradient-to-r from-primary to-primary-dark">
                                    App Store
                                </a>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.tauchapp.tauchapp"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="w-full px-4 py-2 my-2 text-center text-white border rounded-lg border-primary bg-gradient-to-r from-primary to-primary-dark">
                                    Play Store
                                </a>
                            </main>
                        </Route>
                    </Switch>
                </div>
                <footer className="grid max-w-sm grid-cols-2 gap-4 mx-auto my-2 text-sm text-center text-mid-gray">
                    <a
                        href="https://www.tauchapp.com/impressum/"
                        target="_blank"
                        rel="noopener noreferrer">
                        Impressum
                    </a>
                    <a
                        href="https://www.tauchapp.com/privacy/"
                        target="_blank"
                        rel="noopener noreferrer">
                        Datenschutz
                    </a>
                </footer>
            </div>
        </Router>
    )
}

export default App
