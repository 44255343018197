import React, { useState, useEffect } from 'react'
import axios from 'axios'

const PasswordReset = () => {
	const [code, setCode] = useState()
	const [password, setPassword] = useState('')
	const [passwordConfirmation, setPasswordConfirmation] = useState('')
	const [feedback, setFeedback] = useState()
	const [finished, setFinished] = useState(false)

	useEffect(() => {
		let params = new URL(document.location).searchParams
		setCode(params.get('code'))
	}, [])

	const handleSubmit = e => {
		e.preventDefault()

		if (!password || password !== passwordConfirmation) {
			setFeedback('Bitte Passwort angeben')
			return
		}

		if (
			!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/)
		) {
			setFeedback(
				'Passwort muss mindestens 8 Zeichen, eine Zahl und Groß/Kleinschreibung enthalten'
			)
			return
		}

		axios
			.post(`${process.env.REACT_APP_BACKEND_URL}auth/reset-password`, {
				code,
				password,
				passwordConfirmation,
			})
			.then(response => {
				setFinished(true)
			})
			.catch(error => {
				setFeedback(`Fehler beim Zurücksetzen`)
				console.log(error.response)
			})
	}

	return (
		<main className="max-w-sm mx-auto">
			<h2 className="text-2xl">Passwort ändern</h2>

			{finished ? (
				<>
					<p className="my-4">Passwort erfolgreich zurückgesetzt!</p>
					<a
						href={`${process.env.REACT_APP_MAGIC_LINK}`}
						className="w-full px-4 py-2 my-2 text-center text-white border rounded-lg border-primary bg-gradient-to-r from-primary to-primary-dark">
						Zurück zur TauchApp
					</a>
				</>
			) : (
				<form onSubmit={handleSubmit}>
					{feedback && <p class="text-red-500">{feedback}</p>}
					<label className="flex flex-col">
						<span className="sr-only">Passwort</span>
						<input
							className="w-full p-2 my-2 border rounded-lg outline-none border-mid-gray focus:border-accent"
							type="password"
							placeholder="Passwort"
							value={password}
							onChange={e => setPassword(e.target.value)}
						/>
					</label>
					<label className="flex flex-col">
						<span className="sr-only">Passwort wiederholen</span>
						<input
							className="w-full p-2 my-2 border rounded-lg outline-none border-mid-gray focus:border-accent"
							type="password"
							placeholder="Passwort wiederholen"
							value={passwordConfirmation}
							onChange={e =>
								setPasswordConfirmation(e.target.value)
							}
						/>
					</label>
					<button className="w-full px-4 py-2 my-2 text-center text-white border rounded-lg border-primary bg-gradient-to-r from-primary to-primary-dark">
						Passwort ändern
					</button>
				</form>
			)}
		</main>
	)
}

export default PasswordReset
